import { collection, doc, getDoc, getDocs, getFirestore, query, where } from 'firebase/firestore'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useSigninCheck } from 'reactfire'
import { TeamDef } from '../../interfaces/teamDef'
import Loader from '../appStates/LoadingView'
import { setBillingAccount } from '../context/billingAccountSlice'
import { setNeedsOnboarding } from '../context/onboardingSlice'
import { setTeam, setTeams } from '../context/teamsSlice'
import { setUser } from '../context/userSlice'

interface AuthWrapperProps {
    children: JSX.Element
    publicRoutes: JSX.Element
    onboardingRoutes: JSX.Element
}

interface LoadingState {
    isLoading: boolean
    error: string | null
    dataLoaded: boolean
}

const AuthWrapper = ({ children, publicRoutes, onboardingRoutes }: AuthWrapperProps) => {
    const db = getFirestore()
    const dispatch = useDispatch()

    const { data: signInCheckResult, status } = useSigninCheck()

    const [loadingState, setLoadingState] = useState<LoadingState>({
        isLoading: true,
        error: null,
        dataLoaded: false,
    })
    const { needsOnboarding } = useSelector((state: any) => state.onboarding)

    useEffect(() => {
        const loadInitialData = async () => {
            setLoadingState({ isLoading: true, error: null, dataLoaded: false })
            try {
                if (!signInCheckResult?.signedIn || !signInCheckResult?.user) {
                    setLoadingState({ isLoading: false, error: null, dataLoaded: true })
                    return
                }

                const userId = signInCheckResult.user.uid

                const userDoc = await getDoc(doc(db, 'users', userId))
                const userData = userDoc.data()
                dispatch(setUser(userData))

                let billingAccountData, teamData

                if (userData?.lastBillingAccountViewed) {
                    const billingAccount = await getDoc(doc(db, 'billingAccounts', userData.lastBillingAccountViewed))
                    billingAccountData = billingAccount.data()
                    dispatch(setBillingAccount(billingAccountData))
                }

                if (userData?.lastTeamViewed) {
                    const [teamDoc, teamsQuery] = await Promise.all([
                        getDoc(doc(db, 'teams', userData.lastTeamViewed)),
                        getDocs(query(collection(db, 'teams'), where('membersIds', 'array-contains', userId))),
                    ])

                    teamData = teamDoc.data()
                    dispatch(setTeam(teamData ?? []))
                    dispatch(setTeams(teamsQuery.docs.map((doc) => doc.data() as TeamDef)))
                }

                const isLegacyPlan = billingAccountData?.plan?.pricingVersion === 'v1'
                const onboardingNeeded = isLegacyPlan
                    ? false
                    : !userData ||
                      !userData.email ||
                      !userData.name ||
                      !signInCheckResult.user.displayName ||
                      !userData.lastTeamViewed ||
                      !teamData ||
                      (billingAccountData?.plan?.pricingVersion === 'v2' && !billingAccountData.plan?.id) ||
                      !teamData.brand.alias ||
                      !teamData.brand.primaryColor ||
                      (!teamData.legal_name && !teamData.legalName) ||
                      !teamData.address.zip ||
                      !teamData.rfc ||
                      !teamData.facturapi.completed

                dispatch(setNeedsOnboarding(onboardingNeeded))
                setLoadingState({ isLoading: false, error: null, dataLoaded: true })
            } catch (error) {
                setLoadingState({
                    isLoading: false,
                    error: error instanceof Error ? error.message : 'Failed to load data',
                    dataLoaded: false,
                })
            }
        }

        if (status === 'success') {
            loadInitialData()
        }
    }, [status, signInCheckResult, dispatch, db])

    if (status === 'loading' || loadingState.isLoading) {
        return <Loader loading={true} />
    }

    if (!signInCheckResult?.signedIn) {
        return loadingState.dataLoaded ? publicRoutes : <Loader loading={true} />
    }

    if (needsOnboarding) {
        return loadingState.dataLoaded ? onboardingRoutes : <Loader loading={true} />
    }

    return loadingState.dataLoaded ? children : <Loader loading={true} />
}

export default AuthWrapper
